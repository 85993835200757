<!--
 * @Author: nice
 * @Date: 2021-11-13 12:31:14
 * @LastEditTime: 2021-12-04 11:38:53
 * @LastEditors: nice
 * @Description: 继续支付中订单
-->
<template>
  <section class="h100 continue-pay">
    <NavBar title="支付中" background="blue" @leftClick="leftClick" />
    <van-notice-bar
      v-show="!platformStatus.enable"
      scrollable
      :text="this.platformStatus.msg"
      background="opacity"
      left-icon="volume-o"
    />
    <van-popup v-model="show" position="bottom" :style="{ height: '45%' }">
      <van-picker
        show-toolbar
        :columns="columns"
        :default-index="columns.length - 1"
        @confirm="dateSure"
        @cancel="show = false"
      />
    </van-popup>
    <LoadingS v-if="loading" />
    <template v-if="pageListData.records && pageListData.records.length !== 0">
      <ul :class="['list-wrap']">
        <van-list v-model="loading" :finished="noMore" finished-text="没有更多了" @load="handleInfiniteOnLoadDown">
          <div class="list-interval" v-for="(item, index) in pageListData.records" :key="index">
            <van-cell :border="false">
              <template #title>
                <span>缴费金额：</span>
                <span class="money-yellow">{{ item.totalFee }}</span>
                <span>元</span>
              </template>
              <template #default>
                <span v-show="platformStatus.enable" class="again-pay-btn" @click="handleContinuePay(item)">
                  继续支付
                </span>
              </template>
            </van-cell>
            <van-grid :column-num="3">
              <van-grid-item>
                <template #text>
                  <div class="title-text">水费</div>
                  <div class="content-text">{{ item.waterFee }}元</div>
                </template>
              </van-grid-item>
              <van-grid-item>
                <template #text>
                  <div class="title-text">污水处理费</div>
                  <div class="content-text">{{ item.treatment }}元</div>
                </template>
              </van-grid-item>
              <van-grid-item>
                <template #text>
                  <div class="title-text">垃圾清运费</div>
                  <div class="content-text">{{ item.garbageFee }}元</div>
                </template>
              </van-grid-item>
              <van-grid-item>
                <template #text>
                  <div class="title-text">违约金</div>
                  <div class="content-text">{{ item.lateFee }}元</div>
                </template>
              </van-grid-item>
              <van-grid-item>
                <template #text>
                  <div class="title-text">账户预存</div>
                  <div class="content-text">{{ item.rechargeAmount }}元</div>
                </template>
              </van-grid-item>
              <van-grid-item>
                <template #text>
                  <div class="title-text">缴费方式</div>
                  <div class="content-text">{{ item.payType ? item.payType.payTypeName : '-' }}</div>
                </template>
              </van-grid-item>
            </van-grid>
          </div>
        </van-list>
      </ul>
    </template>
    <template v-else>
      <van-empty v-if="!loading" description="暂无数据" />
    </template>
    <div class="btn-wrap-x">
      <van-button type="info" class="self-btn" color="#5196FF" @click="goHome">返回首页</van-button>
    </div>
  </section>
</template>

<script>
import LoadingS from '@/components/loading/Loading.vue'
import moment from 'moment'
import { getPayRecord, getPlatformStatus } from '@/services/payServer.js'
import { goToPayUrlWithWxCode } from '@/services/httpUrlConfig.js'
export default {
  components: {
    LoadingS,
  },
  data() {
    const { accountId = '', customerNumberId = '', keyword } = this.$route.query
    return {
      keyword,
      current: 1,
      columns: [],
      show: false,
      currentYear: moment().year(),
      loading: false,
      noMore: false,
      pageListData: {},
      /**
       * accountId、customerNumberId 都填=全部订单查询
       */
      parameters: {
        size: 20,
        current: 1,
        paymentState: 3,
        appFlag: true,
        // accountId: accountId, // 填入账户号id=查询所有充值到账户的订单
        customerNumberId: customerNumberId || '', // 填入用户号id=查询所有缴费订单和充值到表端的订单
      },
      platformStatus: {
        enable: true, // 默认开启
        msg: '', // 当平台停用时的消息提示
      }, // 平台启用状态
    }
  },
  mounted() {
    this.createDate()
  },
  created() {
    this.hideshare()
    this.getListData()
    this.loadPlatformStatus()
  },
  methods: {
    onBridgeReady() {
      WeixinJSBridge.call('hideOptionMenu')
    },
    hideshare() {
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
        }
      } else {
        this.onBridgeReady()
      }
    },
    // 动态生成年份
    createDate() {
      const startDate = moment().subtract(10, 'year').year() // 前10年
      const endDate = moment().year()
      for (let i = startDate; i <= endDate; i++) {
        this.columns.push(i)
      }
    },
    // 获取分页数据
    getListData() {
      this.loading = true
      if (this.noMore) return
      getPayRecord({ ...this.parameters })
        .then((res) => {
          if (+res.code === 9200) {
            this.loading = false
            if (this.parameters.current === 1) {
              this.pageListData = { ...res.data }
            } else {
              if (!this.noMore) {
                this.pageListData = { ...res.data, records: [...this.pageListData.records, ...res.data.records] }
              }
            }
            this.noMore = +res.data.lzPage.pages === +res.data.lzPage.current
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    handleInfiniteOnLoadDown() {
      this.parameters.current += 1
      this.noMore = false
      this.$nextTick(() => {
        this.getListData()
      })
    },
    dateSure(value) {
      this.currentYear = value
      this.show = false
    },
    handleContinuePay(item) {
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
        if (platform.state) {
          const parames = {
            orderId: item.orderId,
            amount: item.totalFee,
            customerNumber: this.keyword,
          }
          goToPayUrlWithWxCode(parames)
        } else {
          Notify({
            type: 'danger',
            message: this.platformStatus.msg,
          })
        }
      })
    },

    goHome() {
      this.$router.push({ name: 'accountInfo', query: { keyword: this.keyword } })
      // this.$router.push({ name: 'payHome'});
    },
    // 自定义返回
    leftClick() {
      this.$router.push({ name: 'accountInfo', query: { keyword: this.keyword } })
    },
    /**
     * 微信公众号是否可访问
     */
    loadPlatformStatus: function () {
      getPlatformStatus().then((res) => {
        let platform = this.util.getTranscateRst(res)
        this.platformStatus.enable = platform.state
        this.platformStatus.msg = platform.msg
      })
    },
  },
}
</script>

<style lang="less">
.continue-pay {
  .list-wrap {
    height: calc(100vh - 46px - 25px - 50px - 10px - 5px);
    padding: 0;
    background: none;
    .list-interval {
      margin-bottom: 5px;
      .money-yellow {
        color: #f2a445;
      }
      .again-pay-btn {
        padding: 5px 6px;
        background-color: #0581fe;
        color: white;
        border-radius: 5px;
        font-size: 3.733vw;
      }
      .title-text {
        font-size: 3.733vw;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.7);
      }
      .content-text {
        font-size: 4vw;
        color: rgba(0, 0, 0, 0.9);
      }
    }
  }
  .date-wrap {
    color: rgba(16, 16, 16, 100);
    font-size: 14px;
    height: 25px;
    line-height: 35px;
    padding: 0 16px;
    .year-month {
      margin-right: 5px;
    }
  }
  .btn-wrap-x {
    height: 50px;
    padding: 0 13px;
    margin-top: 5px;
    .self-btn {
      height: 40px;
      width: 100%;
    }
  }
}
</style>
